.copy-inputGroup {
  width: 100%;
}

.copy-input {
  width: 100%;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.copy-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

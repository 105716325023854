//
//
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

// Navbar

// .nav-user {
//   max-width: 140px;
// }

.navbar-brand {
  margin-right: 5.5rem;
}

// Provider button colors - variables in user-variables

.btn-primary,
.btn-provider,
.btn-primary-2,
.btn-primary-3,
.btn-provider:hover {
  color: $white;
  border: none;
}

.btn-google {
  background-color: $google;
}
.btn-youtube {
  background-color: $google;
}
.btn-twitch {
  background-color: $twitch;
}
.btn-facebook {
  background-color: $facebook;
}
.btn-email {
  background-color: $email;
}
.btn-provider:hover.btn-google {
  background-color: $google-hover;
}
.btn-provider:hover.btn-twitch {
  background-color: $twitch-hover;
}
.btn-provider:hover.btn-facebook {
  background-color: $facebook-hover;
}

// Align header links center with plan upgrade link included:
.navbar-collapse.justify-content-end.nav-user.collapse {
  // max-width: 280px;
  max-width: 320px;
}

// Disabled card

.card.disabled .card-body > select,
.card.disabled .card-body > p,
.card.disabled .card-body > div:not(.service-controls) {
  opacity: 0.4;
}

// Status icons

.go-live-status .go-live-status__indicator {
  font-family: $headings-font-family;
}
.go-live-status__indicator .fa {
  color: $red;
}

// Autosave fields

// .form-group {
//     position: relative;
//     .inline-form-autosave-icons {
//         position: absolute;
//         top: 35px;
//         right: 0px;
//         height: 30px;
//         width: 20px;

//         .fa-check {
//             color: $green;
//         }
//     }
// }

.card-header {
  font-family: $headings-font-family;
}

// Preview player
.preview-player {
  // TODO center align
  background-color: black;
  text-align: center;
  .spinner-border {
    margin: 100px 0;
  }
}

// Live Log Terminal View

.terminal-view {
  width: 100%;
  height: 16em;
  vertical-align: bottom;
  overflow: hidden;
  background-color: $black;
  color: white;
  font-family: $headings-font-family;
  // font-size: 0.7em;
  font-size: 0.65em;
  line-height: 1.3em;

  // Bottom up scroll
  // display: flex;
  // flex-direction: column;
}

.terminal-view {
  // box-sizing: border-box;
}

.terminal-view .terminal-view__provider {
}

.terminal-view .terminal-view__provider-streamon {
  color: $orange;
}

// terminal-view__title terminal-view__title-youtube

.terminal-view .terminal-view__provider-youtube {
  color: $google;
}
.terminal-view .terminal-view__provider-twitch {
  color: $twitch;
}
.terminal-view .terminal-view__provider-facebook {
  color: $facebook;
}

.terminal-view .terminal-view__title.terminal-view__title--error {
  color: red;
}

// Card Header
.card-header {
  padding: 1rem 2rem;
}

// Accordion

// TODO: fix the square border when only one service is visible...

.accordion {
  margin-bottom: 2em;
  > .card {
    overflow: visible;
    margin-bottom: -23px;

    .dropdown {
      margin-right: 0.8em;
    }

    .avatar {
      margin-right: 0.5em;
    }

    .service-disabled {
      h5 {
        color: #adb5bd;
      }
    }
    .custom-switch {
      padding-left: 2rem;
    }
  }
  h5 {
    margin-bottom: 0;
  }
}

// Audio Only carousel
// TODO: could split this out into component

.carousel-control-prev,
.carousel-control-next {
  -webkit-filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.9));
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.9));
}

.video-loop-overlay {
  // position: absolute;
  // top: 0;
  // right: 0;
  // bottom: 0;
  // left: 0;

  // border: 1px solid red; // debug, this is the area to capture and save out to png

  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

  margin-top: calc(-30px - 56.25%); /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  margin-bottom: 50px;

  &.dark > div {
    color: #444;
  }
  &.light > div {
    color: white;

    -webkit-filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.9));
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.9));
  }

  > div {
    font-family: Arial, Helvetica, sans-serif;
  }

  // .video-loop-overlay-top {
  // }
  .video-loop-title {
    text-align: center;
    // height: 50%;
    position: absolute;
    bottom: 50%;
    left: 10%;
    right: 10%;

    // display: table-cell;
    // vertical-align: bottom;
    // vertical-align: bottom;

    font-size: 40px;
  }

  .video-loop-sub-title {
    text-align: center;
    // height: 50%;
    position: absolute;
    top: 50%;
    left: 10%;
    right: 10%;

    font-size: 30px;
  }
}

// Upgrade pricing:

.inline-upgrade-message {
  margin-top: -15px;
}

// Checkboxes

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $orange;
  background-color: $orange;
}

// Share Action Buttons

.btn-square {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
}
// .frontpage_square img{
// position:absolute;
// }

.action-btn {
  position: relative;
  overflow: hidden;
  // padding-bottom: 100%;

  width: 150px;
  height: 150px;
  color: white;
  text-align: center;

  /* margin: 1em 0.5em 1em !important; */
  overflow: hidden;
  padding: 20px 12px !important;
  margin: 5px;

  .fab,
  .fa {
    font-size: 2em;
    &::before {
      color: white;
    }
  }
}

.action-delete {
  position: relative;
  top: 7px;
  right: 25px;
  color: white;
}

// CSS truncation with ellipsis helper
.truncate {
  display: inline-block;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Fix iframe embeds not fitting the page
.kg-card iframe {
  max-width: 91vw;
}

// Dark Mode Overlay

.dark-mode {
  background-color: $black;
  color: $gray-200;

  .dropdown-menu {
    background-color: $black;
    color: $gray-800;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $gray-200;
  }

  .breadcrumb {
    background-color: $gray-800;
    .breadcrumb-item + .breadcrumb-item::before {
      color: $gray-200;
    }
  }

  .alert {
    opacity: 0.6;
  }

  .table-bordered {
    border: 1px solid $gray-800;
  }
  .table {
    color: $gray-200;
  }
  .table thead th {
    border-bottom: 2px solid $gray-200;
    color: $gray-200;
  }

  .jumbotron {
    background-color: $gray-800;
  }

  .custom-file-label {
    color: $gray-200;
    background-color: $black;
    border: 1px solid $gray-800;
  }
  .custom-file-label::after {
    color: $gray-200;
    background-color: $gray-800;
  }

  // Checkboxes

  .custom-control-label:before {
    border: 1px solid $gray-800;
  }
  .custom-control-label::before {
    background-color: $gray-800;
    border: $gray-200 solid 1px;
  }

  // Connect Page

  .form-control {
    color: $gray-200;
    background-color: $black;
    border-color: $gray-800;
    // border-width: 2px;
  }

  .custom-select {
    // color: #495057;
    // background-color: #fff;
    // border: 1px solid #dee2e6;
    color: $gray-200;
    background-color: $black;
    border-color: $gray-800;
  }

  .card {
    color: $gray-200;
    background-color: $black;
    border: 1px solid $gray-800;
  }

  // Footer

  footer.bg-secondary {
    background-color: $gray-800 !important;
  }
}

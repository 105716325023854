@keyframes stream {
  from {
    color: white;
  }
  to {
    color: red;
  }
}

.streaming {
  animation-name: stream;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}



/* Basic button styling */

.pulsingButton {
    // width: 220px;
    text-align: center;
    white-space: nowrap;
    display: block;
    // margin: 50px auto;
    // padding: 10px;
    box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
    border-radius: 10px;
    // background-color: #FF0000;
    -webkit-animation: pulsing 1.75s infinite cubic-bezier(0.8, 0, 0, 1);
    -moz-animation: pulsing 1.75s infinite cubic-bezier(0.8, 0, 0, 1);
    -ms-animation: pulsing 1.75s infinite cubic-bezier(0.8, 0, 0, 1);
    animation: pulsing 1.75s infinite cubic-bezier(0.8, 0, 0, 1);
    // font-size: 22px;
    font-weight: normal;
    font-family: sans-serif;
    text-decoration: none !important;
    color: #ffffff;
    transition: all 300ms ease-in-out;
  }
  
  
  /* Comment-out to have the button continue to pulse on mouseover */
  
  a.pulsingButton:hover {
    -webkit-animation: none;
    -moz-animation: none;
    -ms-animation: none;
    animation: none;
    color: #ffffff;
  }
  
  
  /* Animation */
  
  @-webkit-keyframes pulsing {
    to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
    }
  }
  
  @-moz-keyframes pulsing {
    to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
    }
  }
  
  @-ms-keyframes pulsing {
    to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
    }
  }
  
  @keyframes pulsing {
    to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
    }
  }
  
// Allow pulse button to show correclty
.nav-user { 
  overflow-y: visible;

  .pulsingButton {
    position: relative;
    top: 11px;
  }
}

// Layout of the On Air Broadcaster
.on-air {

  min-height: 100vh;

  .navbar {

  }

  .stream-preview {
    min-height: 460px;
    background-color: $gray-800;
    position: relative;
    &__video-tag-wrapper {
      // transform: scale(0.5);
      // margin-bottom: -315px;
    }
    &__canvas-wrapper {
      position: absolute;
      // left: calc(50% - 270px); // The width of the player / 2 -- 560px/2
      top: -166px;
      z-index: 2;
      padding-bottom: 1rem;
      transform: scale(0.5);
      .canvas-scale{
      }
    }
  }
}


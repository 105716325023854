
// Custom Home Page Section with On Air Vide
.on-air-section {

    // background-color: #7A7B80;
    background-color: #706F73;
    padding: 0;

    &__title {
        color: white;
    }

    &__video {
        width: 100vw;
    }
}
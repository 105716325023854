//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.

// Color Scheme
// Uncomment the variables below and change the hex values.

@import url('https://fonts.googleapis.com/css?family=Open+Sans|Space+Mono&display=swap');

// Many colors
$orange: #f78764;
$green: #69dc9e;
$blue: #30bced;
$font-family-sans-serif: 'Open Sans', SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
  'Courier New', monospace;
$font-family-base: $font-family-sans-serif;
$font-family-monospace: 'Open Sans', SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
  'Courier New', monospace;
$headings-font-family: 'Space Mono', monospace;
$headings-line-height: 1.8;

$primary: $orange;
$primary-2: $blue;
$primary-3: $green;

// Provider Brand colors
$google: #ff0000;
$google-hover: #c70000;
$twitch: #6441a4;
$email: #6C757D;
$twitch-hover: #4816a4;
$facebook: #4267b2;
$facebook-hover: #1447ae;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
  'primary': $primary,
  'primary-2': $primary-2,
  'primary-3': $primary-3,
);

$theme-colors: (
  'primary': $primary,
  'primary-2': $primary-2,
  'primary-3': $primary-3,
);

// Font Awesome for Brand icons

@import '../fontawesome/scss/fontawesome.scss';
@import '../fontawesome/scss/solid.scss';
@import '../fontawesome/scss/brands.scss';
